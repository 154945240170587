<template>
  <div class="home-ejercicio">
      <h1>Bienvenidos a los indicadores del ejercicio del cargo</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>